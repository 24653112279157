.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 55px;
    width: 100%;
    background-color: #ffecff;

}

.header__logo {
   display: block;
   display: flex;
    img {
        width: 90px;
        height: 80px;
    }
}

.nav__ul {
    display: flex;  
}

.nav__a {
    padding: 10px;
    color: black;
    
}

.navbar__burger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    display: none;
    margin-right: 20px;
}

@media  screen and (max-width:664px) {

    .header__logo {
        display: block;
        display: flex;
         img {
             width: 70px;
             height: 60px;
         }
     }


    .nav__ul {
        flex-direction: column;
        justify-content: center;
        position: fixed;
        right: -600px;
        bottom:0px;
        height: 100%;
        padding: 32px;
        background-color: #ffecff;
        align-items: center;
        visibility: hidden;
        z-index: 1;
    }

    .show-nav .nav__ul {
            right: 0px;
            width: 100%;
            visibility: visible;
        
    }

    
        
    .nav__a {
        color: black;
        display: block;
        padding: 24px;
        font-size: 30px;
        
    }

    .navbar__burger {
        display: block;
        &:hover {
            cursor: pointer;
        }
    }


    

    .burger-line,
    .burger-line::before,
    .burger-line::after {
        display: block;
        width: 40px;
        height: 3px;
        position: relative;
        border-radius: 3px;
        background: black;
        z-index: 2;
    }

    .burger-line::before,
    .burger-line::after {
        content:"" ;
        position: absolute;
        left: 0px;
    }

    .burger-line::before,
    .burger-line::after {
        content:"" ;
    }

    .burger-line::before {
        transform: translateY(-12px);
    }

    .burger-line::after {
        transform: translateY(12px);
    }

    .show-nav .burger-line::before {
        transform: rotate(45deg);
    }
    
    .show-nav .burger-line::after {
        transform: rotate(-45deg);
    }

    .show-nav .burger-line {
        background: transparent;
    }

    
}