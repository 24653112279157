// Rate

.star__container {
    margin: 10px 0px 10px 0px;
    min-width: max-content;

    .color {
        color: #f5c842;
        font-size: 16px;
    }

    .grey {
        color: #E3E3E3;
        font-size: 16px;
    }
}