// Card openclassrooms

.card__openclassrooms {
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 25px 0px 25px 0px;
    padding: 56px 50px 0px 50px;

    .openclassrooms__project {
        width: 25%;
        margin-bottom: 40px;
        margin-right: 2px;
        
        :hover {
            filter: opacity(50%);
        }


        a {
            display: block;

           
           

            img {
                object-fit: cover;
                width: 100%;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                height: 300px;
                filter: brightness(90%);
                vertical-align: bottom;
            }


            .title {
                
                height: 25px;
                background-color: purple;
                display: flex;

                h3 {
                    text-align: center;
                    margin: auto;
                    position: relative;
                    color: white;
                    font-size: 18px;
                }
            }

            
        }
    }
}

@media screen and (max-width:1090px) {
    .card__openclassrooms {
        .openclassrooms__project {
            width: 40%;
        }
    }
    
}

@media screen and (max-width:664px) {
    .card__openclassrooms {
        .openclassrooms__project {
            width: 100%;
        }
    }
}