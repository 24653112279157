@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 1.42857143;
  
  }

a {
       text-decoration: none;
       color: black;
}

ul {
    list-style-type: none;
}

.container {
  padding: 0px 100px;
  max-width: 1440px;
  margin: 0 auto;
}


@media  screen and (max-width:1090px) {
  .container {
    padding: 0px 20px;


  } 
}